import React from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import Helmet from 'react-helmet';

import Layout from '../../components/layout';
import AppleGoogleStores from '../../components/appleGoogleStores';
import vivokiStep1 from '../../data/images/start/scale-step1-account.png';
import vivokiStep2 from '../../data/images/start/scale-step2-link.png';
import vivokiStep3 from '../../data/images/start/scale-step3-tracking.png';
import phone from '../../data/images/screen-phone-myday.png';
import themeColors from '../../styles/theme';

const tempStrings = {
    vivokiTitle: 'Get started with your vívokí',
    createAccount: 'Create Account',
    createAccountContent: 'Create a Connect account or sign in to Garmin Connect.',
    linkVivoki: 'Link vívokí',
    linkVivokiContent: 'Go to the Account Information in Settings to link your vivoki.',
    trackVivoki: 'Begin Tracking',
    trackVivokiContent: 'Go to the dashboard to view your progress.',
    seeYourActivity: 'See Your Activity Data on the Go',
    seeYourActivityContent:
        'Download the Garmin Connect Mobile app on your smartphone to transfer, analyze and share your activity data.',
    seeYourActivityCompatible: (
        <span>
            Don’t have a <a href="https://support.garmin.com/">compatible phone</a>? You can use the Garmin Connect
            website on your computer, but you will need to sync your data via a vívohub wireless access client in your
            workplace.
        </span>
    ),
    howToUseVivoki: 'How to use your vívokí',
    howToUseVivokiGoal: 'Goal',
    howToUseVivokiGoalContent:
        'Once you activate vívokí, your step goal is set to 5,000 steps per day. You can turn on the auto goal feature or set a personalized goal in Garmin Connect.',
    howToUseVivokiWear: 'Wear',
    howToUseVivokiWearContent: 'Wear vívokí on your waistband, belt, torso or in your pocket.',
    howToUseVivokiTrack: 'Track',
    howToUseVivokiTrackContent:
        'Your vívokí keeps track of your daily steps, distance traveled, calories burned and intensity of exercise.',
    howToUseVivokiTap: 'Tap',
    howToUseVivokiTapContent:
        "Check your progress throughout the day by tapping vívokí 2-3 times. A flashing LED light indicates you've met 10% of your daily goal, and each solid LED indicates 20%. When all five are flashing, you've exceeded your goal.",
    howToUseVivokiSync: 'Sync',
    howToUseVivokiSyncContent:
        'Pair the device with the Garmin Connect Mobile app or pass within range of your workplace vívohub wireless access client to transfer your data.',
    howToSetUpVivoki: 'How to set up your vívokí',
    haveQuestions:
        'Have questions? Contact your wellness coordinator or the person who gave you your device for more information.'
};

const styles = {
    section: {
        padding: '30px 10% 30px 10%',
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid rgba(199,199,199,0.3)',
        '& h2': {
            flexBasis: '100%',
            fontSize: '2.7em',
            textAlign: 'center',
            lineHeight: '40px',
            fontWeight: '500',
            margin: '35px 0 40px 0'
        },
        '& h3': {
            fontSize: '1.9em',
            lineHeight: '30px',
            fontWeight: '500'
        },
        '& h4': {
            fontWeight: 400,
            fontSize: '1.5em'
        },
        '& p': {
            fontWeight: 400,
            fontSize: '1.2em',
            width: '60%'
        }
    },
    instructions: {
        flexBasis: '100%',
        display: 'flex',
        '& p': {
            width: '80%',
            paddingLeft: '10%'
        },
        '& h3': {
            fontWeight: 400,
            fontSize: '1.5em'
        }
    },
    greyBackground: {
        backgroundColor: '#F8F8F8'
    },
    number: {
        minWidth: '40px',
        height: '40px',
        margin: '10px 0 10px 0',
        '& span': {
            width: '40px',
            border: '1px solid',
            height: '40px',
            borderRadius: '50%',
            fontSize: '14px',
            textAlign: 'center',
            lineHeight: '40px',
            display: 'block'
        }
    },
    image: {
        width: '50%'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        '& img': {
            width: '50%'
        }
    },
    seeData: {
        display: 'flex'
    },
    seeDataText: {
        flexBasis: '50%',
        '& a': {
            color: themeColors.primaryTextLinks,
            outline: 'none'
        },
        '& span a': {
            backgroundColor: themeColors.white
        },
        '& h3': {
            width: '60%'
        },
        '& > p:nth-of-type(2)': {
            fontSize: '1em'
        }
    },
    seeDataImage: {
        flexBasis: '50%',
        '& img': {
            width: '40%',
            marginLeft: '20%'
        }
    },
    appStores: {
        display: 'flex'
    },
    howToContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    questions: {
        width: '50%',
        marginLeft: '25%'
    },
    videos: {
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%',
        '& iframe': {
            left: '0',
            top: '0',
            height: '100%',
            width: '100%',
            position: 'absolute'
        }
    },
    howToSetUp: {
        width: '39%'
    },
    howToUse: {
        width: '60%'
    },
    '@media only screen and (max-width: 767px)': {
        column: {
            marginBottom: '10px'
        },
        appStores: {
            '& > div': {
                marginRight: '10px'
            }
        },
        instructions: {
            flexDirection: 'column'
        },
        seeData: {
            flexDirection: 'column',
            '& h3': {
                textAlign: 'center',
                width: '100%'
            },
            '& p': {
                width: '100%'
            },
            '& img': {
                display: 'none'
            }
        },
        howToContainer: {
            flexDirection: 'column',
            '& p': {
                width: '100%'
            }
        },
        questions: {
            width: '100%',
            marginLeft: '0'
        },
        howToSetUp: {
            width: '100%'
        },
        howToUse: {
            width: '100%'
        }
    }
};

class Vivoki extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Layout backgroundColor="#fafafa">
                <div>
                    <Helmet>
                        <title>{tempStrings.vivokiTitle}</title>
                        <meta name="description" content="It only takes a few steps to set up your Garmin vívokí." />
                    </Helmet>
                    <div className={classNames(classes.section, classes.greyBackground)}>
                        <h2>{tempStrings.vivokiTitle}</h2>
                        <div className={classes.instructions}>
                            <div className={classes.column}>
                                <div>
                                    <img src={vivokiStep1} alt="" />
                                </div>
                                <div className={classes.number}>
                                    <span>1</span>
                                </div>
                                <div>
                                    <h3>{tempStrings.createAccount}</h3>
                                    <p>{tempStrings.createAccountContent}</p>
                                </div>
                            </div>
                            <div className={classes.column}>
                                <div>
                                    <img src={vivokiStep2} alt="" />
                                </div>
                                <div className={classes.number}>
                                    <span>2</span>
                                </div>
                                <div>
                                    <h3>{tempStrings.linkVivoki}</h3>
                                    <p>{tempStrings.linkVivokiContent}</p>
                                </div>
                            </div>
                            <div className={classes.column}>
                                <div>
                                    <img src={vivokiStep3} alt="" />
                                </div>
                                <div className={classes.number}>
                                    <span>3</span>
                                </div>
                                <div>
                                    <h3>{tempStrings.trackVivoki}</h3>
                                    <p>{tempStrings.trackVivokiContent}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.section}>
                        <div className={classes.seeData}>
                            <div className={classes.seeDataText}>
                                <h3>{tempStrings.seeYourActivity}</h3>
                                <p>{tempStrings.seeYourActivityContent}</p>
                                <br />
                                <div className={classes.appStores}>
                                    <AppleGoogleStores />
                                </div>
                                <br />
                                <p>{tempStrings.seeYourActivityCompatible}</p>
                            </div>
                            <div className={classes.seeDataImage}>
                                <span>
                                    <img src={phone} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={classes.section}>
                        <div className={classes.howToContainer}>
                            <div className={classes.howToUse}>
                                <h3>{tempStrings.howToUseVivoki}</h3>
                                <h4>{tempStrings.howToUseVivokiGoal}</h4>
                                <p>{tempStrings.howToUseVivokiGoalContent}</p>
                                <h4>{tempStrings.howToUseVivokiWear}</h4>
                                <p>{tempStrings.howToUseVivokiWearContent}</p>
                                <h4>{tempStrings.howToUseVivokiTrack}</h4>
                                <p>{tempStrings.howToUseVivokiTrackContent}</p>
                                <h4>{tempStrings.howToUseVivokiTap}</h4>
                                <p>{tempStrings.howToUseVivokiTapContent}</p>
                                <p>{tempStrings.howToUseVivokiTrackContent}</p>
                                <h4>{tempStrings.howToUseVivokiSync}</h4>
                                <p>{tempStrings.howToUseVivokiSyncContent}</p>
                            </div>
                            <div className={classes.howToSetUp}>
                                <h3>{tempStrings.howToSetUpVivoki}</h3>
                                <div className={classes.videos}>
                                    <iframe
                                        title="Youtube id bf2HrnpJ7v8"
                                        width="420"
                                        height="315"
                                        src="https://www.youtube-nocookie.com/embed/bf2HrnpJ7v8?rel=0&showinfo=0"
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                                <br />
                                <div className={classes.videos}>
                                    <iframe
                                        title="Youtube id 3Z8qt7q8Tn4"
                                        width="420"
                                        height="315"
                                        src="https://www.youtube-nocookie.com/embed/3Z8qt7q8Tn4?rel=0&showinfo=0"
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.section}>
                        <h4 className={classes.questions}>{tempStrings.haveQuestions}</h4>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(Vivoki);
