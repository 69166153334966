import React from 'react';
import injectSheet from 'react-jss';

import googleAppStoreImg from '../data/images/store-google-@2x.png';
import googleAppStoreChinaImg from '../data/images/app-download-android-cn.png';
import appleAppStoreImg from '../data/images/store-apple-@2x.png';
import appleAppStoreChinaImg from '../data/images/app-download-apple-cn.png';
import { isChinaUser } from '../config/isChina';

const styles = {
    app: {
        position: 'relative',
        top: 0,
        display: 'block',
        borderRadius: '2.5px',
        border: '2px solid #000',
        width: '150px',
        height: '55px'
    },
    googleAppStore: {
        background: `#000 url(${googleAppStoreImg}) no-repeat center center/133px 45.5px`,
        composes: '$app',
        marginRight: '10px'
    },
    googleAppStoreChina: {
        background: `#000 url(${googleAppStoreChinaImg}) no-repeat center center/133px 45.5px`,
        composes: '$app',
        marginRight: '10px'
    },
    appleAppStore: {
        background: `#000 url(${appleAppStoreImg}) no-repeat center center/133px 45.5px`,
        composes: '$app'
    },
    appleAppStoreChina: {
        background: `#000 url(${appleAppStoreChinaImg}) no-repeat center center/133px 45.5px`,
        composes: '$app'
    },
    '@media only screen and (max-width: 767px)': {
        googleAppStore: {
            margin: 0
        }
    }
};

/**
 * Get other Garmin apps - lists pther available garmin connect apps
 */
class AppleGoogleStores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChinaUser: false
        };
    }

    componentDidMount() {
        this.setState({ isChinaUser: isChinaUser() });
    }

    render() {
        const { classes } = this.props;
        const androidLink = this.state.isChinaUser
            ? 'https://download.garmin.cn/cn/download/CH_SW_UPGRADE/Android/garmin-connect-mobile.apk'
            : 'https://play.google.com/store/apps/details?id=com.garmin.android.apps.connectmobile';
        const appleLink = this.state.isChinaUser
            ? 'https://itunes.apple.com/cn/app/garmin-connect-mobile/id583446403?mt=8'
            : 'https://itunes.apple.com/us/app/garmin-connect-mobile/id583446403?mt=8&amp;uo=4';
        const cssAndroidClass = this.state.isChinaUser ? classes.googleAppStoreChina : classes.googleAppStore;
        const cssAppleClass = this.state.isChinaUser ? classes.appleAppStoreChina : classes.appleAppStore;
        return (
            <>
                <div>
                    <a
                        className={cssAndroidClass}
                        href={androidLink}
                        title="Garmin Connect Mobile"
                        target="play_store"
                    />
                </div>
                <div>
                    <a className={cssAppleClass} href={appleLink} title="Garmin Connect Mobile" target="itunes_store" />
                </div>
            </>
        );
    }
}

export default injectSheet(styles)(AppleGoogleStores);
